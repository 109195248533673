import React,{useRef,useEffect} from 'react';
import { Segment, Header, Button, Dropdown} from 'semantic-ui-react';
import TableComponent from './Components/TableComponent'
import Expression from './Components/ExpressionCK'
import {baseUrl} from '../../config.jsx'
import MultiSelectFetchPost from '../../lib/MultiSelectFetchPost'



const EditDelete=(props)=>
{

    const {api,Cols,GroupBy,Where,fetchTableColumns,createDeleteColList,vars,databases,tableCols,changeState,sql}=props

    const Tables=useRef([])
     
    const fetchTables=()=>
    {
       Tables.current=Array.from(new Set(Cols.concat(GroupBy)))
       Tables.current.forEach(table=>{
            fetchTableColumns(table)
       })
    }

    useEffect(()=>{
        fetchTables()
   
    },[Cols,GroupBy])

   
   
    const changeColsState =(event,value)=>
    {
        changeState('Cols',value)
        Tables.current=Array.from(new Set(Tables.current.concat(value)));
        fetchTables()
        if(GroupBy.length >0)
        // add all trables into USING
        changeState('GroupBy',[...Tables.current])
    }   
   
    const changeGroupByState =(event,value)=>
    {
        Tables.current=Array.from(new Set(Cols.concat(value)));
        if(value.length===Tables.current.length)
            changeState('GroupBy',[])
        else
            changeState('GroupBy',[...Tables.current])
        fetchTables()
    }


    
  
    return (
        <div>
            <Header as='h5'>DELETE FROM</Header>
            <Segment>
                <MultiSelectFetchPost placeholder='Tabelle' 
                            url={'https://'+api+'.'+baseUrl+'/db/tables/'}
                            params={databases}
                            keyText='Table' keyKey='Table' keyValue='Table'

                            value={Cols}
                            name='Table'
                            handleChange={changeColsState}  />
                    

            </Segment> 
     
            <Header as='h5'>USING</Header>
            <Segment>
                           <MultiSelectFetchPost placeholder='Tabelle' 
                            url={'https://'+api+'.'+baseUrl+'/db/tables/'}
                            params={databases}
                            keyText='Table' keyKey='Table' keyValue='Table'

                            value={GroupBy}
                            name='Table'
                            handleChange={changeGroupByState}  />
                    
           </Segment> 
           <Header as='h5'>WHERE</Header>
            <Segment>   
                <Expression
                    changeState = {changeState}
                    ParaName={'Where'}
                    Value={Where}
                    sql={sql}
                    joinAliases={Tables.current}
                    tableCols={tableCols}
                    vars={vars}
                />  
            </Segment>
    
        </div>
    )
}
export default EditDelete
