import {baseUrl} from './config.jsx'
import {apiGet,apiPut,apiDelete,apiPost} from './lib/api'

export const postStatement= async (api,sql,type,url,unique=null)=>
{
  if(sql!==undefined && url !== undefined )
  {
    let extra= unique ? '?unique=true' :''

    const  data= await apiPost(`/statements/${type}/${url}${extra}`,sql,api)
    return data.url
  }
} 



export const deleteStatement= async (api,type,url)=>
{
    await apiDelete(`/statements/${type}/${url}`,api) 
}

