      import React,{Component} from 'react';
import { Segment, Header, Button} from 'semantic-ui-react';

const ShowUpdateStatement=(props)=>
{
    if(props.sql!== undefined)
    return   <div>
        <Segment>
            <Header as="h1">Actual SQL Statement</Header>
        </Segment>
        <Segment>
            <Header as="h5">UPDATE</Header>
            <p>
                {props.sql.Tables}
            </p>
             <Header as="h5">SET</Header>
            <p>
                {props.sql.Cols} 
            </p>
            {
                props.sql.Where!=='' ?
                <div>
                    <Header as="h5">WHERE</Header>
                    <p>
                        {props.sql.Where}
                    </p>
                </div>
                 : ''
            }
        </Segment>
        <Segment>
            <Header as="h5">Error Status</Header>
            <p>
                {props.lint}
            </p>
        </Segment>
    </div>
    else
        return ''
}
export default ShowUpdateStatement
