import React,{useState,useRef,useEffect} from 'react';
import {baseUrl} from '../../config.jsx'

import EditSelect from './EditSelect'
import EditUpdate from './EditUpdate'

import EditInsert from './EditInsert'
import EditDelete from './EditDelete'





const SqlStatement=(props)=>
{
    const {api,databases,sql,getTableAlias,changeTableAlias,tables,setTables,deleteTableAlias,joinAliases,updateStatement,vars, tableCols,stmtType, fetchTableColumns}=props
    const [alias,setAlias]=useState(sql.Tables[0].Alias)
    const [join,setJoin]=useState(sql.Tables.length ? sql.Tables[0].Join.map(el=>(el.Alias)) : {_empty:''})
    const [cols,setCols]=useState(sql.Cols)
    const [where,setWhere]= useState(sql.Where)
    const [groupBy,setGroupBy]= useState(sql.GroupBy)
    const [orderBy,setOrderBy]=useState(sql.OrderBy)
    const nextSql=useRef(sql.Tables[0]);
    
    const addJoinTable= (alias,table,join=[]) =>
    {
        alias=getTableAlias(alias,table);
        setJoin((prevState)=>(
        
            [...prevState,alias ]
        ));
        nextSql.current.Join.push({Alias:alias,Table:table,Join:join});
    }
  
    const changeJoinAlias=(alias,table,newAlias=null) =>
    {
        // this is now one step up in hirarchie once called
        newAlias=changeTableAlias(alias,table,newAlias);
        setJoin((prevState)=>(
            
            prevState.map((el)=>el===alias?newAlias:el)
            
        ))
        return newAlias
    }
  
   
    const deleteJoin= (alias) =>
    {
        setJoin((prevState)=>(
        
            prevState.filter(el => el!==alias)
        ));
        deleteTableAlias(alias);
           
        nextSql.current.Join=nextSql.current.Join.filter(el=>el.Alias!==alias);
        updateStatement()
    }
  
   


    const changeState= (ParaName,Value) =>
    {
       if(ParaName==='Table')
        {
            let newAlias=alias
            let Alias=alias
            if(Alias.substr(0,6)==='_empty')
            {
                newAlias=changeTableAlias(Alias,Value,Value.split('.')[1])
                nextSql.current.Alias=newAlias
                setAlias(newAlias);
            }
            else
            {
                changeTableAlias(alias,Value)
            }
            //this.changeJoinAlias(Alias,Value,newAlias)
            nextSql.current.Table=Value;
        }
        else if(ParaName==='Alias')
        {
            let newAlias=changeTableAlias(alias,
                        joinAliases[alias]/*Table*/,
                        Value);
            setAlias(newAlias);
            nextSql.current.Alias=newAlias;
        }
        sql[ParaName]=Value 
        switch(ParaName)
        {
            case 'Join':
                setJoin(Value)
            break
            case 'Cols':
                setCols(Value)
            break
            case 'Where':
                 setWhere(Value)
            break;         
            case 'GroupBy':
                 setGroupBy(Value)
            break;
            case 'OrderBy':
                 setOrderBy(Value)
            break;
        }
        updateStatement()

    }
  
   
    
 

   
    
  
    useEffect(()=>{
        updateStatement()
    },[])
    
   
    switch(stmtType)
    {
        case 'select':    
        
            return <EditSelect
                    databases={databases}
                    joinAliases={joinAliases}
                    Alias={alias}
                    changeState={changeState}
                    addJoinTable={addJoinTable}
                    Join={join}
                    nextSql={nextSql.current}
                    sql={sql}
                    getTableAlias={getTableAlias}
                    changeTableAlias={changeTableAlias} 
                    changeJoinAlias={changeJoinAlias}
                    updateStatement= {updateStatement}
                    deleteTableAlias={deleteTableAlias}
                    deleteJoin ={deleteJoin}
                    tableCols={tableCols}          
                    Select={cols}        
                    Where={where}
                    GroupBy={groupBy}
                    OrderBy={orderBy}
                    api={api}
                    tables={tables}
                    setTables={setTables}
                    vars={vars}
            />

        case 'update':

                return <EditUpdate
                    databases={databases}
                    joinAliases={joinAliases}
                    Alias={alias}
                    changeState={changeState}
                    addJoinTable={addJoinTable}
                    Join={join}
                    nextSql={nextSql.current}
                    sql={sql}
                    getTableAlias={getTableAlias}
                    changeTableAlias={changeTableAlias} 
                    changeJoinAlias={changeJoinAlias}
                    updateStatement= {updateStatement}
                    deleteTableAlias={deleteTableAlias}
                    deleteJoin ={deleteJoin}
                    tableCols={tableCols}          
                    Cols={cols}        
                    Where={where}
                    api={api}
                    vars={vars}
                    tables={tables}
            />

        case 'insert':

                return <EditInsert
                    databases={databases}
                    joinAliases={joinAliases}
                    Alias={alias}
                    changeState={changeState}
                    addJoinTable={addJoinTable}
                    Join={join}
                    nextSql={nextSql.current}
                    sql={sql}
                    getTableAlias={getTableAlias}
                    changeTableAlias={changeTableAlias} 
                    changeJoinAlias={changeJoinAlias}
                    updateStatement= {updateStatement}
                    deleteTableAlias={deleteTableAlias}
                    deleteJoin ={deleteJoin}
                    tableCols={props.tableCols}    
                    GroupBy={groupBy}      
                    Cols={cols}  
                    api={api}      
                    vars={vars}

            />

        case 'delete':

                return <EditDelete
                    databases={databases}
                    joinAliases={joinAliases}
                    Alias={alias}
                    changeState={changeState}
                    Join={join}
                    nextSql={nextSql.current}
                    sql={sql}
                    api={api}
                    updateStatement= {updateStatement}
                    GroupBy={groupBy}
                    tableCols={tableCols}          
                    Cols={cols}     
                    fetchTableColumns={fetchTableColumns}   
                    Where={where}
                    vars={vars}
            />


        default:
            return <div></div>
    }

    
}
export default SqlStatement

