import {baseUrl} from '../config.jsx'

const debug=false
let loginMessageDisplayed=false

const errorHandling= async (data,api)=>
{
    if(data.status>400)
    {
        sessionStorage.removeItem(api);
        if(!loginMessageDisplayed)
        {
          loginMessageDisplayed=true
          alert('Anmeldung abgelaufen. Seite wird neu geladen')
        }

        window.location.href = `/${api}/login?redirectPath=${window.location.pathname}&redirectQuery=${window.location.search}&api=${api}`
    }
     if(data.status>=300)
    {
      data.json()
      .then(data=>
        console.error(data)
      )
      return false
    }
    return true

}


export const apiGet= async (url,api)=>
    {
       if(url !== undefined )
       {
         let paras= {
              credentials: 'include',
              method: 'GET'
          }
          let data= await fetch('https://'+api+'.'+baseUrl+url, paras)
          if(debug)
            console.log('select',url)
     
          if(await errorHandling(data,api)) 
          {
            data= await data.json()
            return data
          }
       }
    } 



export const apiPost= async (url,body={},api)=>
    {
       if(body!==undefined && url !== undefined )
       {
        let paras= {
              credentials: 'include',
              method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  },
              body: JSON.stringify(body).replace(/\u2028/g, '\\u2028').replace(/\u2029/g, '\\u2029')
          }
          let data= await fetch('https://'+api+'.'+baseUrl+url, paras)
          if(debug)
            console.log('update',url,body)
          if(await errorHandling(data,api)) 
          {
            data= await data.json()
            return data
          }
       
       }
    } 


export const apiPut= async (url,body,api)=>
    {
       
       if(body!==undefined && (body.length>0 || Object.keys(body).length>0) && url !== undefined  )
       {
        let paras= {
              credentials: 'include',
              method: 'PUT',
                headers: {
                  'Content-Type': 'application/json'
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                  },
              body: JSON.stringify(body).replace(/\u2028/g, '\\u2028').replace(/\u2029/g, '\\u2029')
          }
          let data= await fetch('https://'+api+'.'+baseUrl+url, paras)
          if(debug)
            console.log('INSERT',url,body)
          if(await errorHandling(data,api))
          {
            data= await data.json()
            return data
          } 
      }
    
    } 


  export const apiDelete= async (url,api)=>
    {
       let data= await fetch('https://'+api+'.'+baseUrl+url, {
            credentials: 'include',
            method: 'DELETE',
               headers: {
                 'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                }

         })
          if(debug)
             console.log('DELETE',url)
         errorHandling(data,api)

        
    }
   

  
