import React,{Component} from 'react';
import {baseUrl} from '../../../config.jsx'
import SelectFetchPost from '../../../lib/SelectFetchPost'
import { Dropdown} from 'semantic-ui-react';

export default class InsertAlias extends Component
{
    changeState = (Para,Value) =>
    {
        this.props.changeState(Para,Value)
    }
    render=()=>
    {   
        const table= this.props.joinAliases[this.props.Alias]
        return       <SelectFetchPost placeholder='Tabelle' 
                            url={'https://'+this.props.api+'.'+baseUrl+'/db/tables/'}
                            params={this.props.databases}
                            keyText='Table' keyKey='Table' keyValue='Table'
                            classes='selection'
                            value={this.props.joinAliases[this.props.Alias]}
                            name='Table'
                            handleChange={this.changeState}  />
                    
            
    }
}
