import React,{Component} from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Dropdown} from 'semantic-ui-react';

export default class SelectFetch extends Component
{
    constructor(props)
    {
        super(props);
        // leeres Array
        this.state={  
                        options: [],
                        value: ''
                    };
        this.handleSelectChange=this.handleSelectChange.bind(this);
   }
 
    componentDidMount= () =>
    {
        this.fetchFromUrl();
    }
 
    componentDidUpdate= (prevProps) =>
    {
        if(this.props.url!== prevProps.url) 
        {
            this.fetchFromUrl();
        }
    }
    fetchFromUrl= () =>
    {
        fetch(this.props.url,{credentials: "include", "Cache-Control": "no-cache"})
        .then( results => 
            {
                return results.json();
            }
        )
        .then(data=>
        {
            console.log(JSON.stringify(data));
            var newOptions= Object.values(data)[0].map(line =>
            {
                return {
                    key:line[this.props.keyKey],
                    text:line[this.props.keyText],
                    value:line[this.props.keyValue]
                };
            });
            this.setState({options:newOptions})
        })
        .catch( err =>
        {
            //alert(this.props.url+': '+ err.message)
        });
    }
    handleSelectChange(event,data)
    {
        this.props.handleChange(this.props.name,data.value)
    }
    render()
    {
        return <Dropdown placeholder={this.props.placeholder}  
                search  className={this.props.classes}
                onChange={this.handleSelectChange}
                options={this.state.options}
                value={this.props.value} />
    }
}



