import React,{ useState, useEffect, useRef }  from 'react';
import Tabulator from 'tabulator-tables'
//import { ReactTabulator } from 'react-tabulator'
import 'react-tabulator/lib/styles.css'
import 'react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.css'
import {Segment,Button} from 'semantic-ui-react'
import showDatum from './showDatum'

const MyTabulator=(props)=>
{
    const {data,name,columns,children,onChange,onClick,downloads,print,calcResults,...other}=props

    const tab = useRef(null);

    const tabulatorEl = useRef(null)
    const [cellClicked,setCellClicked]=useState(false)

    useEffect(()=>{
       if(cellClicked && onClick)
       {
           onClick(cellClicked)
           setCellClicked(false)
       }

    }, [cellClicked])

   

    useEffect(()=> 
    {
        if(data)
        {
            if(!tab.current  )
            {
                tab.current = new Tabulator(tabulatorEl.current, {
                    data: JSON.parse(JSON.stringify(data)), //link data to table
                    columns: columns, //define table columns
                    dataEdited:(data)=>{
                                    if(onChange)
                                        onChange(data)
                                },
                    cellClick:(e,cell)=>{
                                    if(onClick)
                                        setCellClicked(cell)
                                },
                    renderComplete:()=>
                    {
                         if(calcResults && tab.current)
                            calcResults({values:tab.current.getCalcResults(),name:name})
                    },

                    ...other
                })
            }
            else
            {
                tab.current.setData(JSON.parse(JSON.stringify(data)))
                if(calcResults && tab.current)
                            calcResults({values:tab.current.getCalcResults(),name:name})
            }
           
        }

    }
    ,[JSON.stringify(data), tab.current]
    )


    return (
        <>
            <div ref={tabulatorEl} />
            <div>{children}</div>
            { downloads  || print? 
                <Segment>
                    
                       { !downloads ?  '' :
                        <>
                            <Button icon='file excel' content='Excel File' onClick={e=>{tab.current.download("xlsx", downloads+".xlsx", {sheetName:"Daten"})}}/>
                            <Button icon='file pdf' content='PDF File' onClick={e=>{tab.current.download("pdf", downloads+".pdf")}}/>
                        </>
                    
                       }
            
                       { !print ? '': 
                            <Button icon='print' content='Drucken' onClick={e=>{tab.current.print()}}/>
                       }
                </Segment>
                :''
            }
        </>
    )
}


export const dateFormatter=(cell, formatterParams, onRendered)=>
{
    return showDatum(cell.getValue())
}

export const autoNumFormatter = (cell, formatterParams, onRendered)=>{
       return cell.getRow().getPosition()+1;
};

export default MyTabulator
