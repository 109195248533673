import {replaceMentions,variablesFromArray} from './ReplaceMentions.jsx'
    
export const selectStatement=(sql)=>
{
    if(sql=== undefined)
        return {Cols:'',Tables:[],Where:[],GroupBy:'',OrderBy:''}
    // reset varArray
    sql.varArray=[]
    const Cols=replaceMentions(sql,sql.Cols); // strip html tags from the editor content
    const Tables= joinToSql(sql,sql.Tables[0])
    const Where= replaceMentions(sql,sql.Where);
    const OrderBy= replaceMentions(sql,sql.OrderBy);
    const GroupBy= replaceMentions(sql,sql.GroupBy);
    let stmt= {Cols: Cols, Tables: Tables, Where: Where, GroupBy: GroupBy, OrderBy: OrderBy}
    sql.sqlStatement=composeSelectStatement(stmt)
    return stmt
}

export const composeSelectStatement=(stmt)=>
{
        return stmt=`SELECT ${stmt.Cols} FROM ${stmt.Tables}`+
        (stmt.Where!=='' ? ` WHERE ${stmt.Where}`:'') +
        (stmt.GroupBy!=='' ? ` GROUP BY ${stmt.GroupBy}`:'') +
        (stmt.OrderBy!=='' ? ` ORDER BY ${stmt.OrderBy}`: '')
}


export const updateStatement=(sql)=>
{
    if(sql=== undefined)
        return {Cols:'',Tables:[],Where:[]}
    // reset varArray
    sql.varArray=[]
    const Cols=replaceMentions(sql,sql.Cols); // strip html tags from the editor content
    const Tables= joinToSql(sql,sql.Tables[0])
    const Where= replaceMentions(sql,sql.Where);
    let stmt= {Cols: Cols, Tables: Tables, Where: Where}
    sql.sqlStatement=composeUpdateStatement(stmt)
    return stmt
}

export const composeUpdateStatement=(stmt)=>
{
        return stmt=`UPDATE ${stmt.Tables} SET ${stmt.Cols}`+
        (stmt.Where!=='' ? ` WHERE ${stmt.Where}`:'') 
}


export const insertStatement=(sql)=>
{
    if(sql=== undefined)
        return {Cols:'',Tables:[]}
    // reset varArray
    sql.varArray=[]
    const Cols=variablesFromArray(sql,sql.Cols)
    const Tables= sql.Tables[0].Table
    const Update= replaceMentions(sql,sql.GroupBy,/(\$\w+)|(!\w+)/gi)
    let stmt= {Cols: Cols, Tables: Tables, Update : Update}
    sql.sqlStatement=composeInsertStatement(stmt)
    return stmt
}

export const composeInsertStatement=(stmt)=>
{
    if(stmt.Update==='')
        return stmt =`INSERT INTO ${stmt.Tables} ${stmt.Cols}`
    else
        return stmt =`INSERT INTO ${stmt.Tables} ${stmt.Cols} 
         ON DUPLICATE KEY UPDATE ${stmt.Update}`
    
}


export const deleteStatement=(sql)=>
{
    if(sql=== undefined)
        return {Cols:'',Tables:[],Where:[]}
    // reset varArray
    sql.varArray=[]
    const Tables=sql.Cols; // strip html tags from the editor content
    const Using= sql.GroupBy
    const Where= replaceMentions(sql,sql.Where);
    let stmt= {Tables: Tables, Using: Using, Where: Where}
    sql.sqlStatement=composeDeleteStatement(stmt)
    return stmt
}

export const composeDeleteStatement=(stmt)=>
{
        let from=''
        if(stmt.Tables.length!==0)
        {
            from=stmt.Tables.join()
        }
        let using=''
        if(stmt.Using.length!==0)
        {
            using='USING '+stmt.Using.join()
        }
        return stmt=`DELETE FROM ${from} ${using}`+
        (stmt.Where!=='' ? ` WHERE ${stmt.Where}`:'') 
}



const joinToSql=(sql,table)=>
{
    const [db,Table]=table.Table.split('.')
    let virtTable
    if(db==='SubQuery')
    {
        const sub=sql.subs.find(el=>el.name===Table)
        virtTable=`( ${sub.sql.sqlStatement} )`
    }
    else
    {
        virtTable=table.Table
    }
    let res =( table.Join.length>0 ? '(' : '') + 
        `${virtTable} AS ${table.Alias}`;
    if(table.Join.length > 0)
    {
        table.Join.forEach( Join => {
            res= res + ` ${Join.JoinType} JOIN ${joinToSql(sql,Join)}`
        }
        )
    } 
    res= res +  (table.Join.length>0?')' : '' )
    if(table.On!=='' && table.On !== undefined)
    {
        res=res+' ON ('+replaceMentions(sql,table.On.replace(/<[^>]+>/g, ''))+')';
    }

    return res
}

const joinToSqlNoAlias=(sql,table)=>
{
    let res=( table.Join.length>0 ? '(' : '') + 
        `${table.Table} `;
    if(table.Join.length > 0)
    {
        table.Join.forEach( Join => {
            res= res + ` ${Join.JoinType} JOIN ${joinToSql(sql,Join)}`
        }
        )
    } 
    res= res +  (table.Join.length>0?')' : '' )
    if(table.On!=='' && table.On !== undefined)
    {
        res=res+' ON ('+replaceMentions(sql,table.On.replace(/<[^>]+>/g, ''))+')';
    }

    return res
}
