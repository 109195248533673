import React,{useState,useEffect}  from 'react';

import {baseUrl} from '../config.jsx'
import SqlStatement from './SqlStatement/SqlStatement.jsx';
import ShowLint from './ShowLint/ShowLint'
import {selectStatement,updateStatement,composeSelectStatement,composeUpdateStatement,insertStatement,deleteStatement,composeInsertStatement,composeDeleteStatement} from './ComposeStatement'
import {apiGet,apiPut,apiDelete,apiPost} from '../lib/api'

import { Form, Button, Container, Grid} from 'semantic-ui-react';


const EditSql = (props)=>
{
    const {sql,databases,tables,setTables,tableCols,fetchTableColumns,type,vars, postStatement, name, api}=props

    const [joinAliases,setJoinAliases]=useState({})
    const [sqlStatement,setSqlStatement]=useState('')
    const [sqlLint,setSqlLint]=useState('')




    const initJoinAliases=(Join,alias)=>
    {
        // rekusiv
        alias[Join.Alias]=Join.Table;
        Join.Join.forEach(nJoin=>initJoinAliases(nJoin,alias));
        fetchTableColumns(Join.Table)
        return alias;
    }

    const updateActStatement=()=>
    {
        let stmtObject
        let stmt
        // change sql sqlStatement
        switch(type)
        {
            case 'select':
                stmtObject=selectStatement(sql.current)
                stmt=composeSelectStatement(stmtObject)
                break
        
            case 'update':
                stmtObject=updateStatement(sql.current)
                stmt=composeUpdateStatement(stmtObject)
                break

           case 'insert':
                stmtObject=insertStatement(sql.current)
                stmt=composeInsertStatement(stmtObject)
                break

           case 'delete':
                stmtObject=deleteStatement(sql.current)
                stmt=composeDeleteStatement(stmtObject)
                break
                
        }
        // change statement state in EditSql
        setSqlStatement( stmtObject)
        postStatement(sql.current,name)
                  // test the sql sqlStatement
        fetch('https://'+api+'.'+baseUrl+'/db/lint/', {
            credentials: 'include',
            method: 'POST',
               headers: {
                 'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
            body: JSON.stringify({stmt: stmt}).replace(/\u2028/g, '\\u2028').replace(/\u2029/g, '\\u2029')
        })
        .then( results => 
            {
                return results.json();
            }, 
            results=>
            {
                alert(results)
            }
        ).
        then(
            (data)=>{
                if(data && data.sqlMessage && typeof data.sqlMessage==='string')
                    setSqlLint(data.sqlMessage)
            }
        )
    
    }  

   
   
    const changeTableAlias=(alias,table,newAlias=null)=>
    {
        if(newAlias===null)
        {
            // change this alias to another table
            setJoinAliases((aliases)=>(
                   {...aliases,[alias]:table}
           ));
        }
        else
        {
            // add this as a new alias and remove the old one

            //add a unique new aliuas name
            newAlias=getTableAlias(newAlias,table);

            // delete the old one
            deleteTableAlias(alias);
        }
        return newAlias;
    }



    const getTableAlias =(start,table='') =>
    {
        // adds a new alias and gets back an unique name 
        let alias;
        // start is the name given from the user
        if(joinAliases[start]!== undefined)
        {
            let i=0;
            alias= start;
            while(joinAliases[alias]!== undefined)
            {
                alias = `${start}_${i}`;
                i= i+1;
            }
        }
        else
        {
            alias= start; 
        }
        //  fetch all cols of the table
        fetchTableColumns(table)
        // now we have a unique alias name, so put it with the table into state
        setJoinAliases(aliases=>(
                        {...aliases,[alias]:table}
                    ));
        return alias;
    }



    const deleteTableAlias= (alias)=>
    {

        setJoinAliases((aliases)=>({
            ...aliases,[alias]:undefined
                    }))
            
        
    }



   
    useEffect(()=>{
        // build joinAliases List and cols list in state
        const newJoinAliases=initJoinAliases(sql.current.Tables[0],{}) 
        setJoinAliases(newJoinAliases)
    },[sql.current.Tables[0]])

    
 
    return(
        <Container>
             <Grid columns={2} divided>
                <Grid.Column>
                    <Form>
                        {
                           sql.current===null? '' :
                            <div>
                                <SqlStatement 
                                    api={api}
                                    stmtType={type}
                                    sql={sql.current} 
                                    databases={databases} 
                                    updateStatement={updateActStatement}
                                    getTableAlias={getTableAlias}
                                    changeTableAlias={changeTableAlias} 
                                    joinAliases={joinAliases}
                                    deleteTableAlias={deleteTableAlias}
                                    tableCols={tableCols}
                                    fetchTableColumns={fetchTableColumns}
                                    tables={tables}
                                    setTables={setTables}
                                    vars={vars}
                                    />
                    
                                <Button  onClick={()=>alert(JSON.stringify(sql.current))}>SQL-Json</Button>
                            </div>
                        }
                    </Form>
                </Grid.Column>
                  
                <Grid.Column>
                      <ShowLint
                        sql={sqlStatement}
                        lint={sqlLint}
                        type={type}
                    />
                   
                </Grid.Column>
            </Grid>
        </Container>
    )
}
export default EditSql
