import SelectFetchPost from './SelectFetchPost.jsx';
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Dropdown} from 'semantic-ui-react';



export default class MultipleSelectFetchPost extends SelectFetchPost
{
    constructor(props)
    {
        super(props);
        // leeres Array
        this.state={  
                        options: [],
                        values: ''
                    };
        this.handleMSelectChange=this.handleMSelectChange.bind(this);
    }
    handleMSelectChange(event,data)
    {
        this.props.handleChange(this.props.name,data.value)
    }

    render()
    {
        return <Dropdown placeholder={this.props.placeholder} 
          onChange={this.handleMSelectChange} multiple search
                className={`selection ${this.props.classes}`}
                options={this.state.options}
                value={this.props.value} />
    }

}
