                  
import React,{useState, useEffect, useRef}  from 'react';
import {useHistory, useParams} from 'react-router-dom'
import {baseUrl} from './config.jsx'
import {Segment,Header,Form,Container} from 'semantic-ui-react'
import useQuery from './Hooks/useQuery'

const Login=(props)=>
{
    const [formData, setFormData]=useState({username:'',password:''})
    const debug=true
    const history=useHistory()
    const query=useQuery()
    const redirectPath=query.get('redirectPath') ? query.get('redirectPath') :'/'
    const redirectQuery=query.get('redirectQuery')
    const {api}=useParams()
    
    const handleChange=(e,{name,value})=>
    {
          setFormData(d=>({...d,[name]:value}))
    }

    useEffect(()=>
    {
        const checkAutoLogin= async()=>
        {
            const userName=localStorage.getItem('loginUser')
            const userPassword=localStorage.getItem('loginPassword')
            if(userName && userPassword )
            {
                login({username:userName,password:userPassword})
            }
        }
        checkAutoLogin()

    })

  

    const storeLogin= (data,loginData) =>
    {
        localStorage.setItem(api,Date.now())
        localStorage.setItem('user',JSON.stringify(data.user))
        localStorage.setItem('loginUser',loginData.username)
        localStorage.setItem('loginPassword',loginData.password)
        if(redirectPath)
            history.push(redirectPath+redirectQuery)
        else
            history.push('/')
    }

    function clickLogin(e,{name,value})
    {
        login(formData)
    }
    const login= async (loginData)=>
    {
         let paras= {
              credentials: 'include',
              method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  },
              body: JSON.stringify(loginData).replace(/\u2028/g, '\\u2028').replace(/\u2029/g, '\\u2029')
          }
        const result= await fetch('https://'+api+'.'+baseUrl+'/auth/login',paras)
        if(debug)
            console.log('login','https://'+api+'.'+baseUrl+'/auth/login',formData)
        const data= await result.json()

        if(result.status>=400)
        {
            console.error(data)
        }
        else
        {
           storeLogin(data,loginData)
        }
    }

    // save redirect path 
    fetch('https://'+api+'.'+baseUrl+'/auth/login/success?redirectPath='+window.location.protocol+window.location.hostname+redirectPath+redirectQuery)

    return (
        <Container>
            <Segment>
                <Header as='h2'>Anmelden</Header>
                <Form>
                    <Form.Input
                        name='username'
                        value={formData.username}
                        placeholder= 'E-Mail oder Benutzername'
                        label='E-Mail oder Benutzername'
                        onChange={handleChange}
                    />
                    <Form.Input
                        type='password'
                        value={formData.password}
                        name='password'
                        placeholder= 'passwort'
                        label='Passwort'
                        onChange={handleChange}
                    />
                    <Form.Button
                        icon='save'
                        content='Anmelden'
                        color='blue'
                        onClick={clickLogin}
                    />
                     <Form.Button
                        icon='google'
                        content='über Google anmelden'
                        color='green'
                        onClick={e=>{window.open('https://'+api+'.'+baseUrl+'/auth/google')}}
                    />
                </Form>
            </Segment>
        </Container>
    )
}

export default  Login 
