import React,{useState,useRef,useEffect}  from 'react';
//
import {baseUrl} from './config.jsx'
import MyTabulator  from './lib/MyTabulator'
import UriMenue from './UriMenue'
import 'react-tabulator/lib/styles.css'
import 'react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.css'
import {apiGet,apiPut,apiDelete,apiPost} from './lib/api'

import {
  useHistory,
  useParams
} from 'react-router-dom'

import { Container} from 'semantic-ui-react';

const  UriTable=(props)=>
{
    const [data,setData]=useState([])
    const {api}=useParams()
    const history=useHistory()
    const {loggedIn}=props
    useEffect(()=>{
        const load=async ()=>
        {
            const data=await apiGet('/statements',api)
            setData(data);
        }
        if(loggedIn[api])
            load()

    },[loggedIn])
   
    const cellClick= async (e,cell)=>
    {
        let row=cell.getRow()
        if(cell.getField()==='delete')
        {
            await apiDelete(`/statements/${row._row.data.type}/${row._row.data.url}`, api)
            setData(data=>data.filter(el=>(el.type!==row._row.data.type || el.url!==row._row.data.url)))
        }
        else
        {
            history.push(`/${api}/${row._row.data.type}/${row._row.data.url}`)
        }
    }


     const columns=[
            { title: 'Type', field: 'type', width: 100 },
            { title: 'Url', field: 'url', width: 100 },
            { title: 'Variables', field: 'vars', width: 300 },      
            { title: '', field: 'delete', width: 50, formatter:"buttonCross" },
            { title: 'Description', field: 'comment', width: 300 }
        ]

    return(
    
       
       <Container>
            <UriMenue api={api}/>
            <MyTabulator
               
                columns={columns}
                data={data}
                cellClick={cellClick}
            />
        </Container>
    )
    
}

export default UriTable
