
 const  MysqlFunctions= [
"ACOS",
"ADDDATE",
"ADDTIME",
"ASCII",
"ASIN",
"ATAN",
"ATAN2",
"AVG",
"BIN",
"BINARY",
"CASE",
"CAST",
"CEIL",
"CEILING",
"CHAR_LENGTH",
"CHARACTER_LENGTH",
"COALESCE",
"CONCAT",
"CONCAT_WS",
"CONNECTION_ID",
"CONV",
"CONVERT",
"COS",
"COT",
"COUNT",
"CURDATE",
"CURRENT_DATE",
"CURRENT_TIME",
"CURRENT_TIMESTAMP",
"CURRENT_USER",
"CURTIME",
"DATABASE",
"DATE",
"DATE_ADD",
"DATE_FORMAT",
"DATE_SUB",
"DATEDIFF",
"DAY",
"DAYNAME",
"DAYOFMONTH",
"DAYOFWEEK",
"DAYOFYEAR",
"DEGREES",
"DIV",
"ENCRYPT",
"EXP",
"EXTRACT",
"FIELD",
"FIND_IN_SET",
"FLOOR",
"FORMAT",
"FROM_DAYS",
"FROM_UNIXTIME",
"GREATEST",
"HOUR",
"IF",
"IFNULL",
"INSERT",
"INSTR",
"ISNULL",
"LAST_DAY",
"LAST_INSERT_ID",
"LCASE",
"LEAST",
"LEFT",
"LENGTH",
"LN",
"LOCALTIME",
"LOCALTIMESTAMP",
"LOCATE",
"LOG",
"LOG10",
"LOG2",
"LOWER",
"LPAD",
"LTRIM",
"MAKEDATE",
"MAKETIME",
"MAX",
"MD5",
"MICROSECOND",
"MID",
"MIN",
"MINUTE",
"MOD",
"MONTH",
"MONTHNAME",
"NOW",
"NULLIF",
"OLD_PASSWORD",
"PASSWORD",
"PERIOD_ADD",
"PERIOD_DIFF",
"PI",
"POSITION",
"POW",
"POWER",
"QUARTER",
"RADIANS",
"RAND",
"REGEXP",
"REGEXP_INSTR",
"REGEXP_REPLACE",
"REGEXP_SUBSTR",
"REPEAT",
"REPLACE",
"REVERSE",
"RIGHT",
"ROUND",
"RPAD",
"RTRIM",
"SEC_TO_TIME",
"SECOND",
"SESSION_USER",
"SIGN",
"SIN",
"SPACE",
"SQRT",
"STR_TO_DATE",
"STRCMP",
"SUBDATE",
"SUBSTR",
"SUBSTRING",
"SUBSTRING_INDEX",
"SUBTIME",
"SUM",
"SYSDATE",
"SYSTEM_USER",
"TAN",
"TIME",
"TIME_FORMAT",
"TIME_TO_SEC",
"TIMEDIFF",
"TIMESTAMP",
"TO_DAYS",
"TRIM",
"TRUNCATE",
"UCASE",
"UPPER",
"UNIX_TIMESTAMP",
"USER",
"VERSION",
"WEEK",
"WEEKDAY",
"WEEKOFYEAR",
"YEAR",
"YEARWEEK"
]
export default MysqlFunctions
