import React from 'react'
import {fetchRepo,pushRepo} from './RepoFunctions'
import { useHistory } from "react-router-dom";
import { Dropdown,  Menu} from 'semantic-ui-react'
import {postStatement,deleteStatement} from './StatementApi.jsx'

const newSelect=(api,history)=>
{
    const newSql={
                var1:"",var2:"",var3:"",
                "Cols":"",
                "Tables":[{
                            
                            Alias: "_empty",
                            Table: "",
                            "Join": []
                        }],
                "Where":"",
                "OrderBy": "",
                "GroupBy": "",
                "varArray": [],
                "sqlStatement": ""
            }
    postStatement(api,newSql,'select','NEW:0',true)
    .then(newUrl=>
    {
       history.push(`/${api}/select/${newUrl}`)
    })
}
const newUpdate=(api,history)=>
{
    const newSql={
                var1:"",var2:"",var3:"",
                "Cols":"",
                "Tables":[{
                            
                            Alias: "_empty",
                            Table: "",
                            "Join": []
                        }],
                "Where":"",
                "varArray": [],
                "sqlStatement": ""
            }
    postStatement(api,newSql,'update','NEW:0',true)
    .then(newUrl=>
    {
       history.push(`/${api}/update/${newUrl}`)
    })
}

const newInsert=(api,history)=>
{
    const newSql={
                var1:"",var2:"",var3:"",
                "Cols":[],
                "Tables":[{
                            
                            Alias: "_empty",
                            Table: "",
                            "Join": []
                        }],
                "GroupBy":"",
                "varArray": [],
                "sqlStatement": ""
            }
    postStatement(api,newSql,'insert','NEW:0',true)
    .then(newUrl=>
    {
       history.push(`/${api}/insert/${newUrl}`)
    })
}

const newDelete=(api,history)=>
{
    const newSql={
                var1:"",var2:"",var3:"",
                "Cols":[],
                "GroupBy":[],
                "Tables":[{
                            
                            Alias: "_empty",
                            Table: "",
                            "Join": []
                        }],
                "Where":"",
                "varArray": [],
                "sqlStatement": ""
            }
    postStatement(api,newSql,'delete','NEW:0',true)
    .then(newUrl=>
    {
       history.push(`/${api}/delete/${newUrl}`)
    })
}


const UriMenue=(props)=>
{
  let history = useHistory();
  return <Menu attached='top' color='blue' inverted>
        <Dropdown item simple text='New'>
            <Dropdown.Menu>

                    <Dropdown.Item
                        onClick={()=>newSelect(props.api,history)}
                    >
                        Select
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={()=>newUpdate(props.api,history)}
                    >
                        Update
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={()=>newInsert(props.api,history)}
                    >
                        Insert
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={()=>newDelete(props.api,history)}
                    >
                        Delete
                    </Dropdown.Item>

            </Dropdown.Menu>

        </Dropdown>      
        <Dropdown item simple text='Repo'>
            <Dropdown.Menu>

                    <Dropdown.Item
                        onClick={()=>pushRepo(props.api)}
                    >
                        Push
                    </Dropdown.Item>
                        
                    <Dropdown.Item
                         onClick={()=>fetchRepo(props.api,history)}
                    >
                        Fetch
                    </Dropdown.Item>
     
            </Dropdown.Menu>

        </Dropdown>

    </Menu>
}

export default UriMenue
