      import React,{Component} from 'react';
import { Segment, Header, Button} from 'semantic-ui-react';

const ShowDeleteStatement=(props)=>
{
    if(props.sql!== undefined && props.sql!=='')
    return   <div>
        <Segment>
            <Header as="h1">Actual SQL Statement</Header>
        </Segment>
        <Segment>
            <Header as="h5">DELETE FROM </Header>
            <p>
                {
                    props.sql.Tables.length!==0 ?
                    props.sql.Tables.map((el,Index)=>(Index===0 ? el : `, ${el}`))
                    :''
                }
            </p>
            {
                props.sql.Using.length!==0 ?
                <div>
                <Header as="h5">USING</Header>
                <p>
                {
                    props.sql.Using.map((el,Index)=>(Index===0 ? el : `, ${el}`))
                }
                </p>
                </div>
                :''

            }
            {
                props.sql.Where!=='' ?
                <div>
                    <Header as="h5">WHERE</Header>
                    <p>
                        {props.sql.Where}
                    </p>
                </div>
                 : ''
            }
        </Segment>
        <Segment>
            <Header as="h5">Error Status</Header>
            <p>
                {props.lint}
            </p>
        </Segment>
    </div>
    else
        return ''
}
export default ShowDeleteStatement
