import React,{Component} from 'react'
import { Form, Button, Segment } from 'semantic-ui-react'
import TableAlias from './TableAlias'
import Expression from './ExpressionCK'


// needs the props databases JoinIndex( actual Index in join arary) and tableName, tableAlias, function parentChange
//   Joins (Array of joins)

export default class TableComponent extends Component
{
    constructor(props)
    {
        super(props);
        if(this.props.tableComponent.On===undefined)
        {
            this.props.tableComponent.On=''
        }
        if(this.props.tableComponent.JoinType===undefined)
        {
            this.props.tableComponent.JoinType='INNER'
        }
        this.nextSql=this.props.tableComponent;
   
        this.state={  
            Join:this.props.tableComponent.Join.map(el=>el.Alias),
            On:  this.props.tableComponent.On,
            Alias: this.props.tableComponent.Alias,
            JoinType: this.props.tableComponent.JoinType
        };
    }


    addJoinTable= (alias,table,join=[]) =>
    {
        alias=this.props.getTableAlias(alias,table);
        this.setState((prevState)=>({
            Join : [...prevState.Join, alias ]
        }));
        this.nextSql.Join.push({Alias:alias,Table:table,Join:join});
    }
    getTableAlias= (start) =>
    {
        return this.props.getTableAlias(start);
    }
  
    changeJoinAlias=(alias,table,newAlias=null) =>
    {
        // this is now one step up in hirarchie once called
        newAlias=this.props.changeTableAlias(alias,table,newAlias);
        this.setState((prevState)=>(
            {
                Join : prevState.Join.map((el,index)=>el===alias?newAlias:el),
            }
        ))
        return newAlias
    }
    changeState= (ParaName,Value) =>
    {
        if(ParaName==='Table')
        {
            let newAlias=this.props.Alias
            let Alias=this.props.Alias
            if(Alias.substr(0,6)==='_empty')
            {
                let newAlias=this.props.changeJoinAlias(Alias,Value,Value.split('.')[1])
                this.nextSql.Alias=newAlias
            }
            else
            {
                this.props.changeJoinAlias(Alias,Value);
            }
            this.nextSql.Table=Value;

        }
        else if(ParaName==='Alias')
        {
            // old
            let newAlias=this.props.changeJoinAlias(this.props.Alias,this.nextSql.Table,Value)
            this.nextSql.Alias=newAlias;
        
        }
        else if(ParaName==='On')
        {
            this.setState({On:Value})
            this.nextSql.On=Value
            this.props.updateStatement()
        }
        else if(ParaName==='JoinType')
        {
            this.setState({JoinType:Value})
            this.nextSql.JoinType=Value
            this.props.updateStatement()
        }
        
    }
    deleteRecursiveJoins =(sql)=>
    {
        if(sql.Join!==undefined)
        {
            sql.Join.forEach((el,index)=>{
                this.props.deleteTableAlias(el.Alias);
                this.deleteRecursiveJoins(el.Join)
            })
        }
    } 


    deleteJoin= (alias) =>
    {
        this.setState((prevState)=>(
        {
            Join: prevState.Join.filter(el => el!==alias)
        }));
        this.props.deleteTableAlias(alias);
        this.deleteRecursiveJoins(this.nextSql.Join.find(el=>el.Alias===alias));
        this.nextSql.Join=this.nextSql.Join.filter(el=>el.Alias!==alias);
     }



   createColList=()=>
    {
        if(this.props.joinAliases=== undefined)
            return {}
        let aliases=[]
        Object.keys(this.props.joinAliases).forEach( (alias,index)=>
        {
            const table=this.props.joinAliases[alias]
            const cols=this.props.tableCols[table] ===undefined ? [] : this.props.tableCols[table]
            aliases=[...aliases,...cols.map((el,id)=>
            (
               {
                 name: `${alias}.${el}`,
                 id: `$${alias}.${el}`,
               }
            )
            )]
        })
        return aliases
    }
 
  /*  createColList=()=>
    {
        if(this.props.parentAliases=== undefined)

            return {}
        let aliases=[]
        this.props.parentAliases.forEach( (alias,index)=>
        {
            const table=this.props.joinAliases[alias]
            const cols=this.props.tableCols[table] ===undefined ? [] : this.props.tableCols[table]
            aliases=[...aliases,...cols.map((el,id)=>
            (
               {
                 name: `${alias}.${el}`,
                 id: `$${alias}.${el}`,
               }
            )
            )]
        })
        return aliases
    }*/

    render=() =>
    (   
        <Form.Group>
           <Segment>
                <span>
                   { 
                       (this.state.Join.length===0) ? <Button icon='delete' size='mini' compact circular 
                        onClick={()=>this.props.deleteJoin(this.props.Alias)} 
                        color='red'/> : '' }

                    <TableAlias
                        databases={this.props.databases}
                        joinAliases={this.props.joinAliases}
                        alias={this.props.Alias}
                        joinType={this.state.JoinType}
                        changeState={this.changeState}
                        api={this.props.api}
                        tables={this.props.tables}
                    />

                    <Button size='mini' compact icon='chain' onClick={()=>this.addJoinTable('_empty','')} ></Button>
                
                </span>
                <div>
                    <Expression
                        changeState = {this.changeState}
                        ParaName={'On'}
                        Value={this.state.On}
                        sql={this.props.sql}
                        joinAliases = {this.props.joinAliases}
                        tableCols={this.props.tableCols}
                    />  
                </div>
                {

                    this.state.Join.map( (join,joinIndex)=>
                        <TableComponent  
                            api={this.props.api}
                            key={joinIndex.toString()}
                            Alias={join} 
                            parentAliases={[...this.props.parentAliases,join]}
                            tableComponent={this.nextSql.Join[joinIndex] !== undefined ? this.nextSql.Join[joinIndex] :[]}
                            databases={this.props.databases}
                            updateStatement= {this.props.updateStatement}
                            getTableAlias={this.props.getTableAlias}
                            deleteTableAlias={this.props.deleteTableAlias}
                            changeJoinAlias={this.changeJoinAlias}
                            changeTableAlias={this.props.changeTableAlias} 
                            joinAliases  ={this.props.joinAliases}
                            deleteJoin ={this.deleteJoin}
                            tableCols={this.props.tableCols}
                            sql={this.props.sql}
                            tables={this.props.tables}
                    />
                            
                    )
                }
           </Segment>
        </Form.Group>
    )
}
