      import React,{Component} from 'react';
import { Segment, Header, Button} from 'semantic-ui-react';

const ShowInsertStatement=(props)=>
{
    if(props.sql!== undefined)
    return   <div>
        <Segment>
            <Header as="h1">Actual SQL Statement</Header>
        </Segment>
        <Segment>
            <Header as="h5">INSERT INTO</Header>
            <p>
                {props.sql.Tables}
            </p>
             
            <p>
                {props.sql.Cols }
            </p>
            {
                props.sql.Update==='' ||  props.sql.Update=== undefined ? '' :
                <div>
                    <Header as="h5">ON DUPLICATE KEY UPDATE</Header>
                    <p>
                        {props.sql.Update}
                    </p>
                </div>
            }
        </Segment>
        <Segment>
            <Header as="h5">Error Status</Header>
            <p>
                {props.lint}
            </p>
        </Segment>
    </div>
    else
        return ''
}
export default ShowInsertStatement
