import React,{ useState, useEffect }  from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams

} from "react-router-dom";



import EditSql from './EditSql/EditSqlTop.jsx';
import UriTable from './UriTable.jsx';
import useLoggedIntoApi from './Hooks/useLoggedIntoApi'
import Login from './Login'




const App = () => {
    const [leer,api,type,]=window.location.pathname.split('/')
  
    const [loggedIn,setLoggedIn]=useState({})

    useLoggedIntoApi(api,loggedIn,setLoggedIn)


   

    return( 
        <Router forceRefresh={true}>
            <Switch>

                    <Route path="/:api/login">
                        <Login/>
                    </Route> 
                    
                    <Route exact path="/:api/:type/:url">
                        <EditSql loggedIn={loggedIn}/>
                    </Route>
      
                    <Route path="/:api" >
                        <UriTable loggedIn={loggedIn}/>
                    </Route>                
       

                </Switch>
        </Router>
    )
}

export default App;
