import React from 'react';
import { Dropdown, Input,Popup} from 'semantic-ui-react';

const TableAlias =(props)=>
{

    const {changeState,joinAliases,alias,joinType,tables}=props
    const table= joinAliases[alias]
    const myChangeState=(e,{name,value})=>
    {
        changeState(name,value)
    }

    return (
    <span>
        <Popup
            hoverable={true}

            trigger={<span>{joinType !== undefined ? `${joinType} JOIN`: ''}</span>} 
            content={
                    <Dropdown 
                            options={['INNER','LEFT'].map(value=>({key:value,text:value+' JOIN',value:value}))}
                            onChange={(name,value)=>changeState('JoinType',value.value)} 
                            placeholder='Join Type'
                            selection
                            name="JoinType"
                            value={joinType}
                    />
            }
        />
        &nbsp;
        <Popup
            hoverable={true}
            trigger={<span style={(table===''?{color:'grey'}:{})} >{table==='' ? 'select SQL table': table} </span>}
            content={
                    <Dropdown
                            options={tables.map(t=>({key:t,text:t,value:t}))}
                            selection
                            value={table}
                            placeholder='Table' 
                            name='Table'
                            onChange={myChangeState}  
                    />        
            }
        />
      &nbsp;AS&nbsp;
       <Popup
            trigger={<span>
               {alias}
            </span>}
            content={

                    <Input placeholder='table alias' 
                        value={alias} 
                        onChange={(event,data)=>{changeState("Alias",data.value)}}
                        name='Alias'/>

            }
            hoverable={true}
        />
        </span>
    
    )
}

export default TableAlias
