
import React from 'react'
import {baseUrl} from './config.jsx'
import {apiGet,apiPut,apiDelete,apiPost} from './lib/api'
export const pushRepo= async (api)=>
{
     const result= await apiGet(`/statements/push`, api)
     alert('Statements pushed to JSON Repo: '+result.message )

}


export const fetchRepo= async (api,history)=>
{
     const result=await apiGet(`/statements/fetch`,api)
     alert('Statements fetched from JSON Repo: '+result.message)
     history.push(`/${api}`)
}

