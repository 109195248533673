import React,{Component} from 'react';
import { Segment, Header, Button} from 'semantic-ui-react';
import TableComponent from './Components/TableComponent'
import Expression from './Components/ExpressionCK'
import TableAlias from './Components/TableAlias'


const EditUpdate = (props) =>
{
    const {tableCols,joinAliases,api,Cols,Where,databases,Alias,
            changeState,nextSql,sql,getTableAlias,changeTableAlias,deleteTableAlias,changeJoinAlias, deleteJoin,updateStatement, vars,tables}=props
    return (
    <div>
        <Header as='h5'>UPDATE</Header>
        <Segment>
               <span>
                <TableAlias
                    databases={databases}
                    joinAliases={joinAliases}
                    alias={Alias}
                    changeState={changeState}
                    api={api}
                    tables={tables}
                />

                <Button
                    size='mini'
                    compact icon='chain' 
                    onClick={()=>props.addJoinTable('_empty','')} >
                </Button>
            
            </span>
            {
                    props.Join.map( (join,joinIndex)=>
                    <TableComponent key={joinIndex.toString()}
                        Alias={join} 
                        tableComponent={nextSql.Join[joinIndex] !== undefined ? nextSql.Join[joinIndex] :[]}
                        sql={sql}
                        databases={databases}
                        getTableAlias={getTableAlias}
                        changeTableAlias={changeTableAlias} 
                        changeJoinAlias={changeJoinAlias}
                        updateStatement= {updateStatement}
                        joinAliases  ={joinAliases}
                        deleteTableAlias={deleteTableAlias}
                        parentAliases={[Alias,join]}
                        deleteJoin ={deleteJoin}
                        tableCols={tableCols}
                        api={api}
                    />
                )
            }
        </Segment>
        <Header as='h5'>SET</Header>
        <Segment>
            <Expression
                changeState = {changeState}
                ParaName={'Cols'}
                Value={Cols}
                sql={sql}
                joinAliases = {joinAliases}
                tableCols={tableCols}
                vars={vars}

                /> 
        </Segment> 

        <Header as='h5'>WHERE</Header>
        <Segment>   
            <Expression
                changeState = {changeState}
                ParaName={'Where'}
                Value={Where}
                sql={sql}
                joinAliases = {joinAliases}
                tableCols={tableCols}
                vars={vars}
            />  
        </Segment>
            
    </div>
    )
}

export default EditUpdate
