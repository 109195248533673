import React,{useState,useEffect} from 'react';
import {baseUrl} from '../../config.jsx'
import { Header, Segment, Popup, Input } from 'semantic-ui-react';
import EditMenue from './EditMenue'
import {postStatement,deleteStatement} from '../../StatementApi.jsx'
import {useHistory} from 'react-router-dom'

const EditUrl=(props) =>
{
    
    const {api,sql,vars,setVars,url,type}=props
    const history=useHistory()
    const [baseUrl,setBaseUrl]=useState('')

   const getNoOfVars=()=>
   {
       for( let i=0;i<3;++i)
       {
           if(vars[i]==='')
                return i
       }
       return 3
   }
     
   
   const allVarString =()=>
   {
       let result='/'
       for(let i=0; i<3;++i)
       {
            if(vars[i]==='')
            {
                return result
            }
            else
            {
                if(i>0)
                    result+='/'
                result+= vars[i]
            }
       }
       return result
   }

   
   const changeState=(e,{name,value})=>
   {
       if(name==='url')
       {
           setBaseUrl(value)
       }
       else
       {
           const paraNo=parseInt(name.split(':')[1])
           setVars(vars.map((v,Index)=>(Index===paraNo ? value : v)))
           sql.current['var'+(paraNo+1)]=value
       }
   }
   
   
   
   const popupClose= async (event,data)=>
   {
       let newUrl=`${baseUrl}:${getNoOfVars()}`
       if(url!==newUrl)
       {
            await deleteStatement(api,type,url)
            newUrl= await postStatement(api,sql.current,type,newUrl,true)
            history.push(`/${api}/${type}/${newUrl}`) 
       }
   }
   
   
   
   const type2Url=()=>
   {
       switch(type)
       {
           case 'select': return "SELECT: GET /"
           case 'update': return "UPDATE: POST /"
           case 'insert': return "INSERT: PUT /"    
           case 'delete': return "DELETE: DELETE /"       
        }
   }

   useEffect(()=>
   {
        setBaseUrl(url.split(':')[0])
   },[url])
 
   return (
        <Segment>


            <Header as="h3">
                <EditMenue
                    url={baseUrl}
                    sql={sql}
                    type={type}
                    api={api}

                />
                <span>&nbsp;{type2Url()}</span>
                <Popup
                    trigger={<span>
                        {baseUrl}
                    </span>}
                    content={

                            <Input placeholder='url' 
                                value={baseUrl} 
                                onChange={changeState}

                                name='url'/>
                    }
                    hoverable={true}
                    onClose={popupClose}
                />
                <Popup
                    trigger=
                        {
                            <span>
                                {allVarString()}
                            </span>
                        }
                    content={
                        <span>
                            <Input placeholder='url' 
                                value={vars[0]} 
                                onChange={changeState}
                                name='var:0'/>
                            /
                        <Input placeholder='url' 
                                value={vars[1]} 
                                 onChange={changeState}
                                name='var:1'/>
                            /
                        <Input placeholder='url' 
                                value={vars[2]} 
                                 onChange={changeState}
                                name='var:2'/>
                        </span>

                    }
                    hoverable={true}
                    onClose={popupClose}
                />
            </Header>

        </Segment>
   )
}
export default EditUrl
