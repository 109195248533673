import React,{useState,useRef,useEffect}  from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import MentionEditor from '@wollo/ckeditor-mention';
import MysqlFunctions from './MysqlFunctions.jsx'

 
const  ExpressionCK=(props)=> 
{


    const {sql,joinAliases,changeState,tableCols,Value,ParaName,vars,short}=props
    const dollarList=useRef([])
    const varList=useRef 


    useEffect(()=>
    {
        if(Object.keys(joinAliases).length && Object.keys(tableCols).length)
        {
            let myAliases=[]
        
            Object.keys(joinAliases).forEach( (Alias)=>
            {
                const Table=joinAliases[Alias]
                const Cols=tableCols[Table] ===undefined ? [] : tableCols[Table]
                myAliases=[...myAliases,...Cols.map((el)=>
                (
                {
                    name: short ? `${el}` : `${Alias}.${el}`,
                    id: short ? `$${el}` : `$${Alias}.${el}`,
                }
                )
                )]
            })
           dollarList.current=myAliases
        }
    },[joinAliases,tableCols])

    
    useEffect(()=>
    {
        if(vars)
        {
            let myVars=[
                    { 
                        id : ':_user',
                        name : '_user'
                    },
                    { 
                        id : ':_userPIndex',
                        name : '_userPIndex'
                    },
                    { 
                        id : ':_userGiven',
                        name : '_userGiven'
                    },
                    { 
                        id : ':_userName',
                        name : '_userName'
                    },                
                    { 
                        id : ':_userMail',
                        name : '_userMail'
                    },                      
                    
                    
                ]
            for(let i=0; i<3; ++i)
            {   
                if(vars[i]==='')
                    break
                else
                {
                    myVars[5+i]=
                    { 
                        id : ':'+vars[i],
                        name : vars[i]
                    }
                }
            }
        
            // add the none temporary table subqueries as variables
        
            if(sql.subs)
            {

                sql.subs.forEach(sub=>{
                    if(sub.subType && sub.subType!=='temporary')
                    {
                        myVars=[...myVars,{
                            id: ':'+sub.name,
                            name : sub.name
                        }]
                    }
                })
            }
            varList.current=myVars
        }
    },[vars])


    const feedCols=(query) =>
    {
        // builds an array of austosuugest fields for the editor from JoinTables and then cther cols
   
        return feed(dollarList.current,query)
    }
    
    const feedFunctions=(query) =>
    {
        const array= MysqlFunctions.map(el=> ({id: '!'+el, name: el}))
        return feed(array,query)
    }
    
    const feedVars=(query) =>
    {
      
        return feed(varList.current,query)
 
    }
   
    const feed=(array,queryText) =>
    {
        return new Promise( resolve => {
                setTimeout( () => {
                    const itemsToDisplay = array
                        // Filter out the full list of all items to only those matching the query text.
                        .filter(( item ) => {
                            // Make the search case-insensitive.
                            const searchString = queryText.toLowerCase();

                            // Include an item in the search results if the name or username includes the current user input.
                            return (

                                item.name.toLowerCase().indexOf( searchString) >=0
                            );    
                        }
                        )
                        // Return 200 items max - needed for generic queries when the list may contain hundreds of elements.
                        .slice( 0, 200);

                    resolve( itemsToDisplay );
                }, 200 );
        } )
    }


    const customItemRenderer=( item ) =>
    {
        const itemElement = document.createElement( 'span' );

        itemElement.classList.add( 'custom-item' );
        itemElement.id = `mention-list-item-id-${ item.userId }`;
        itemElement.textContent = `${ item.name } `;

        const usernameElement = document.createElement( 'span' );

        usernameElement.classList.add( 'custom-item-username' );
        usernameElement.textContent = item.id;

        itemElement.appendChild( usernameElement );

        return itemElement;
    }

    
   




   
        return (
            <div className="App">
                <CKEditor
                    editor={ MentionEditor }
                    config ={ {
                        plugins:[...MentionEditor.builtinPlugins.map( plugin => plugin.pluginName ),'Mention'],
                  //      extraPlugins:['Mention'], 
                        mention: {
                                    feeds: [
                                        {
                                            marker: '$',
                                            feed: feedCols,
                                            minimumCharacters: 0
                                        },
                                        {
                                            marker: ':',
                                            feed: feedVars,
                                            minimumCharacters: 0
                                        },    
                                        {
                                            marker: '!',
                                            feed: feedFunctions,
                                            minimumCharacters: 0
                                        }
                                    ],
                                    itemRenderer:customItemRenderer
                                }                        
                    } }

                    data={Value}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        const data = editor.getData();
                        changeState(ParaName,data)
                       
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                   
                />
            </div>
        );
    
}

export default ExpressionCK;


