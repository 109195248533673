import React,{Component} from 'react';
import { Segment, Header, Button} from 'semantic-ui-react';
import TableComponent from './Components/TableComponent'
import Expression from './Components/ExpressionCK'
import TableAlias from './Components/TableAlias'


const EditSelect = (props) =>
{
    const {sql,databases,Alias,joinAliases,Join,
            getTableAlias,deleteTableAlias,changeTableAlias,
            changeJoinAlias,deleteJoin,changeState,createColList,
            Where,GroupBy, OrderBy,Select,vars,
            api,tables,addJoinTable,nextSql,updateStatement, tableCols}=props
    return(

    <div>
        <Header as='h5'>FROM</Header>
        <Segment>
            <span>
                <TableAlias
                    databases={databases}
                    joinAliases={joinAliases}
                    alias={Alias}
                    changeState={changeState}
                    api={api}
                    tables={tables}

                />

                <Button
                    size='mini'
                    compact icon='chain' 
                    onClick={()=>addJoinTable('_empty','')} >
                </Button>
            
            </span>
            {
                    Join.map( (join,joinIndex)=>
                    <TableComponent key={joinIndex.toString()}
                        Alias={join} 
                        tableComponent={nextSql.Join[joinIndex] !== undefined ? nextSql.Join[joinIndex] :[]}
                        sql={sql}
                        databases={databases}
                        getTableAlias={getTableAlias}
                        changeTableAlias={changeTableAlias} 
                        changeJoinAlias={changeJoinAlias}
                        updateStatement= {updateStatement}
                        joinAliases= {joinAliases}
                        deleteTableAlias={deleteTableAlias}
                        parentAliases={[Alias,join]}
                        deleteJoin ={deleteJoin}
                        tableCols={tableCols}
                        api={api}
                        tables={tables}
                        vars={vars}
                    />
                )
            }
        </Segment>
        <Header as='h5'>SELECT</Header>
        <Segment>
            <Expression
                changeState = {changeState}
                ParaName={'Cols'}
                Value={Select}
                sql={sql}
                joinAliases = {joinAliases}
                tableCols={tableCols}
                vars={vars}
                />  
        </Segment> 
        <Header as='h5'>WHERE</Header>
        <Segment>   
            <Expression
                changeState = {changeState}
                ParaName={'Where'}
                Value={Where}
                sql={sql}
                joinAliases = {joinAliases}
                tableCols={tableCols}
                vars={vars}
            />  
        </Segment>
        <Header as='h5'>GROUP BY</Header>
        <Segment>   
            <Expression
                changeState = {props.changeState}
                ParaName={'GroupBy'}
                Value={GroupBy}
                sql={sql}
                joinAliases = {joinAliases}
                tableCols={tableCols}
                vars={vars}
            />  
        </Segment> 
        <Header as='h5'>ORDER BY</Header>
        <Segment>   
            <Expression
                changeState = {props.changeState}
                ParaName={'OrderBy'}
                Value={OrderBy}
                sql={sql}
                joinAliases = {joinAliases}
                tableCols={tableCols}
                vars={vars}
            />  
        </Segment> 
    </div>
    )
}
export default EditSelect
