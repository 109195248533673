import React,{useState,useRef,useEffect}  from 'react';
import { Tab, Modal, Form, Header, Button} from 'semantic-ui-react';
import EditSql from './EditSql'
import  {Parser} from 'qh-flora-sql-parser'

const EditSubTab=(props)=>
{
    const {sql,databases,tables,setTables,tableCols,setTableCols,fetchTableColumns,vars,postStatement,api,url}=props
    const [open,setOpen]=useState(false)
    const [name,setName]=useState('')
    const [subs,setSubs]=useState([])
    const [subTypes,setSubTypes]=useState({})

    useEffect(()=>{
        if(sql.current.subs)
        {
            setSubs(sql.current.subs)
            const mySubTypes= {}
            sql.current.subs.forEach((sub)=>
            {
                mySubTypes[sub.name]= sub.subType ? sub.subType :'temporary'
            })
            setSubTypes(mySubTypes)

        }
    },[sql.current.subs])

    const openModal=(e,{value})=>
    {
        setOpen(true)
    }

    const onChangeName=(e,{value})=>
    {
        setName(value)
    }

    const postMyStatement=(mySql,name)=>
    {
            // get sub
            const sub=sql.current.subs.find(el=>el.name===name)
           // parse Statement in order to get the fields
            if(sub.subType==='temporary')
            {
                let fields
                const parser = new Parser();
                
                try
                {
                    const ast = parser.parse(mySql.sqlStatement)
                    fields=ast.columns.map(c=>(
                            c.as ? c.as : c.expr.type==='column_ref' ? c.expr.column : undefined
                    ))
                    console.log(fields)
                    setTableCols(actTableCols=>({...actTableCols,name:fields}))
                }
                catch(e)
                {}
                sub.fields=fields
            }
            postStatement(sql.current,url)
    }

    const addSubQuery=()=>
    {
        const newSql={
                var1:"",var2:"",var3:"",
                "Cols":"",
                "Tables":[{
                            
                            Alias: "_empty",
                            Table: "",
                            "Join": []
                        }],
                "Where":"",
                "OrderBy": "",
                "GroupBy": "",
                "varArray": [],
                "sqlStatement": ""
            }
        if(name)
        {
            if(sql.current.subs)
                sql.current.subs=[...sql.current.subs,{name:name,sql:newSql}]
            else
                sql.current.subs=[{name:name,sql:newSql}]
            setSubs(sql.current.subs)
            setTables(tables=>([...tables,name]))
            postStatement(sql.current,url)
            setOpen(false)
        }
        else
        {
            alert('bitte Namen eingeben')
        }


    }

    const deleteTab=(e,{name})=>
    {
        if(window.confirm(`Really delete Sub ${name}`))
        {
            setSubs((subs)=>subs.filter(el=>el!==name))
            sql.current.subs=sql.current.subs.filter(el=>el.name!==name)
            postStatement(sql.current,url)
            if(subTypes[name]==='temporary')
            {
                setTables(tables=>tables.filter(t=>t!==name))
            }
        }
    }


    const changeSubQueryType=(e,{value,name})=>
    {
        const curType=subTypes[name]
        
        setSubTypes(types=>({...types,[name]:value}))
        // get sub
        const sub=sql.current.subs.find(el=>el.name===name)
        sub.subType=value
        if(curType==='temporary' && value !== 'temporary')
        {
            // remove subquery from tables
            setTables(tables=>tables.filter(t=>t!==name))
            
        }
        else if(curType!=='temporary' && value==='temporary')
        {
            setTables(tables=>([...tables,name]))
        }
        postMyStatement(sub.sql.sqlStatement,name)

    }

    const  panes=
                    subs.map(pane=>(
                        {
                            menuItem:pane.name,
                            render:()=>( 
                                <>
                                    <Form>
                                        <Form.Group
                                            inline
                                        >
                                            <label>Sub Query Type</label>
                                            <Form.Radio
                                                label='Temporary Table'
                                                value='temporary'
                                                onChange={changeSubQueryType}
                                                checked={subTypes[pane.name]==='temporary'}
                                                name={pane.name}
                                            />
                                            <Form.Radio
                                                label='Field'
                                                value='field'
                                                onChange={changeSubQueryType}
                                                checked={subTypes[pane.name]==='field'}
                                                name={pane.name}
                                            />
                                            <Form.Radio
                                                label='Correlated Field'
                                                value='correlated'
                                                onChange={changeSubQueryType}
                                                checked={subTypes[pane.name]==='correlated'}
                                                name={pane.name}
                                            />
                                        </Form.Group>
                                    </Form>
                                    <EditSql
                                        databases={databases}
                                        tableCols={tableCols}
                                        fetchTableColumns={fetchTableColumns}
                                        type='select' 
                                        sql={{current:pane.sql}}
                                        postStatement={postMyStatement}
                                        name={pane.name}
                                        vars={vars}
                                        api={api}
                                        tables={tables}
                                    />
                                    <Button icon="trash" onClick={deleteTab} name={pane.name}/>
                                </>)
                        }
                        ))

    return (
        <>
            <Header as="h2" content="Sub Select Query"/>
            <Button icon='plus' content="Sub Query" onClick={openModal}/>
            <Tab panes={panes}/>

            <Modal open={open}>
                <Modal.Header as="h3">Name of Sub Query</Modal.Header>
                <Modal.Content>
                    <Form.Input
                        value={name}
                        onChange={onChangeName}
                    />

                </Modal.Content>
                 <Modal.Actions>
                    <Button color='red' content='Cancel' onClick={() => setOpen(false)}/>
                    <Button onClick={addSubQuery} color='green' content='ok' icon='check'/>
                </Modal.Actions>
            </Modal>
        </>



    )


}

export default EditSubTab


