
import moment from 'moment';

export function emptyDatum(value,process)
{
    if(value) 
      return   process(value)
    else
      return   ''
}


function showDatum(value,format='D.M.Y')
{


    return value===null || value===undefined || parseInt(value)===0 ? '' 
        : 
        moment(value*1000).format(format)
}


export default showDatum
