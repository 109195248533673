
import {AllHtmlEntities} from 'html-entities';


const htmlEntities = new AllHtmlEntities


const stripHtmlTags=(html)=>
    {
        let text= html.replace(/<[^>]+>/g, '')
        text=htmlEntities.decode(text)
        text= text.replace(/\u00A0/g,' ')
        return text
    }

export const replaceMentions= (sql,expression,regex=/(\$\w+\.\w+)|(!\w+)/gi)=>
    {
        if(sql===undefined)
            return ''
        if(sql.varArray===undefined)
            sql.varArray=[]
        let result=stripHtmlTags(expression)
        result=result.replace(regex,(str)=>(str.substr(1)))
        result=result.replace(/(:\w+)/gi,(str)=>{
            let res=str.substr(1)
            // check if it is a subQuery
            
            const sub=sql.subs ? sql.subs.find(el=>el.name===res && el.subType!=='temporary') :undefined
            if(sub)
            {
                return `( ${sub.sql.sqlStatement} )`
            }
            else if(res===sql.var1)
                sql.varArray.push({type:'url',name:'urlPara1'})
            else if(res===sql.var2)
                sql.varArray.push({type:'url',name:'urlPara2'})
            else if(res===sql.var3)
                sql.varArray.push({type:'url',name:'urlPara3'})
            else if(res=='_userName')
                sql.varArray.push({type:'url',name:'_userName'})
            else if(res=='_userPIndex')
                sql.varArray.push({type:'url',name:'_userPIndex'})
            else
                sql.varArray.push({type:'query',name: res})

            return '?'
        })
        // body variables preceeded by =# 
        result=result.replace(/=\s*#(\w+)/gi,(str,varName)=>{

            sql.varArray.push({type:'body',name: varName})
            return '= ?'
        }
        )
        return result;
    }

export const variablesFromArray=(sql,a)=>
{
    sql.valArray=a
    return '('+a.join()+') VALUES ('+a.map(el=>'?')+')'
}

