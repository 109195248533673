import React, { useState, useEffect, useRef } from 'react';
import { useHistory} from "react-router-dom";
import {baseUrl} from '../config.jsx'




export default function useLoggedIntoApi(api,loggedIn,setLoggedIn,otherApis=[]) {

    const actLoggedIn=useRef([])


    useEffect(() => {
        async function loginApi() {

            let result= await fetch('https://'+api+'.'+baseUrl+'/auth/login/success?redirectPath='+window.location.href,{credentials: "include"})
            result= await result.json()
            if(result.success)
            {
                localStorage.setItem(api,Date.now())
                setLoggedIn(act=>({...loggedIn,[api]:true}))
                actLoggedIn.current[api]=true
                localStorage.setItem('user',JSON.stringify(result.user))
                otherApis.forEach(oApi=>
                {
                    fetch('https://'+oApi+'.'+baseUrl+'/auth/login/success?auth_token='+result.jwt,{credentials: "include"})
                    .then(data=>{
                        setLoggedIn(act=>({...act,[oApi]:true}))
                        localStorage.setItem(oApi,Date.now())
                    })
                })
    
            }
            else     
            {          
          
                if(window.location.pathname!==`/${api}/login`)
                    window.location.href = `/${api}/login?redirectPath=${window.location.pathname}&redirectQuery=${window.location.search}`
            }
        }



        const lastLogin=localStorage.getItem(api)
        if(lastLogin===null || Date.now()-lastLogin>3600000 || (!actLoggedIn.current[api] && !loggedIn[api]) )
        {
           if(window.location.pathname!==`/${api}/login`)
            loginApi()
        }
        otherApis.forEach((oApi)=>
        {
           const lLogin=localStorage.getItem(oApi)
           if(lLogin===null || Date.now()-lLogin>3600000 )
           {
               if(window.location.pathname!==`/${api}/login`)
                loginApi()
           }
           else if(!loggedIn[oApi])
           {
             setLoggedIn({...loggedIn,[oApi]:true})
           }
        })
    },[api,otherApis]);
}
