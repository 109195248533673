import React,{Component} from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Dropdown} from 'semantic-ui-react';

export default class SelectFetchPost extends Component
{
    constructor(props)
    {
        super(props);
        // leeres Array
        this.state={  
                        options: [],
                        value: ''
                    };
        this.handleSelectChange=this.handleSelectChange.bind(this);
   }
 
    componentDidMount= () =>
    {
        this.fetchFromUrl();
    }
 
    componentDidUpdate= (prevProps) =>
    {
        if(JSON.stringify(this.props.params)!== JSON.stringify(prevProps.params)) 
        {
            this.fetchFromUrl();
        }
    }
    fetchFromUrl= () =>
    {
        if(!Array.isArray(this.props.params))
            return;
        if(this.props.params.length ===0)
            return;
        fetch(this.props.url,{
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "include",
                "Cache-Control": "no-cache",
                headers: {
                                 'Content-Type': 'application/json'
                           },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(this.props.params) // body data type must match "Content-Type" header
                }
        )
        .then( results => 
            {
                return results.json();
            }
        )
        .then(data=>
        {
            console.log(JSON.stringify(data));
            var newOptions= Object.values(data)[0].map(line =>
            {
                return {
                    key:line[this.props.keyKey],
                    text:line[this.props.keyText],
                    value:line[this.props.keyValue]
                };
            });
            this.setState({options:newOptions})
        })
        .catch( err =>
        {
           // alert(this.props.url+': '+ err.message)
        });
    }
    handleSelectChange = (event,data) =>
    {
        this.props.handleChange(this.props.name,data.value)
    }
    render()
    {
        return <Dropdown placeholder={this.props.placeholder}  
               search className={`${this.props.classes}`}
                onChange={this.handleSelectChange}
                options={this.state.options}
                value={this.props.value} />
    }
}



