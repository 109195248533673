import React,{Component} from 'react';
import { Segment, Header, Button} from 'semantic-ui-react';
import TableComponent from './Components/TableComponent'
import Expression from './Components/ExpressionCK'
import TableAlias from './Components/InsertAlias'
import MultipleSelectFetch from '../../lib/MultipleSelectFetch'
import {baseUrl} from '../../config.jsx'

const EditInsert =(props)=>
{

    const {api,sql,Cols,GroupBy,changeState,Alias,joinAliases,tableCols,databases,vars}=props
   
    
    const joinArray=joinAliases? Object.values(joinAliases).filter(el=>el) : []
    const [myDatabase,myTable]=joinArray.length>0 ? joinArray[0].split('.') : ['','']
    return (
    
        <div>
            <Header as='h5'>INSERT INTO</Header>
            <Segment>
                <span>
                    <TableAlias
                        databases={databases}
                        joinAliases={joinAliases}
                        Alias={Alias}
                        changeState={changeState}
                        api={api}
                    />
            
                </span>
            </Segment>
            <Header as='h5'>allowed Value Cols</Header>
            <Segment>
                   <MultipleSelectFetch placeholder='allowed fields' 
                            url={'https://'+api+'.'+baseUrl+`/db/fields/${myDatabase}/${myTable}`}
                            keyText='Field' keyKey='Field' keyValue='Field'
                            
                            value={Cols}
                            name='Cols'
                            handleChange={changeState}  />
            </Segment> 
              <Segment>
            <Header as='h5'>On Duplicate Key UPDATE set statement (optional)</Header>
                <Expression
                    changeState = {changeState}
                    ParaName={'GroupBy'}
                    Value={GroupBy}
                    sql={sql}
                    joinAliases = {joinAliases}
                    tableCols={tableCols}
                    short={true}
                    vars={vars}
                    />  
            </Segment> 
    
  
        </div>
    )
    
}
export default EditInsert
