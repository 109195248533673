import React,{useState,useRef,useEffect}  from 'react';
import {useHistory,useParams} from 'react-router-dom'
import {apiGet,apiPut,apiDelete,apiPost} from '../lib/api'
import {baseUrl} from '../config.jsx'
import { Form, Button, Container, Grid, Tab, Header} from 'semantic-ui-react';

import {postStatement} from '../StatementApi.jsx'

import EditSql from './EditSql'
import EditUrl from './EditUrl/EditUrl'
import TestStatement from './TestStatement'
import EditSubTab from './EditSubTab'

const EditSqlTop = (props)=>
{
    const [databases,setDatabases]=useState([])
    const [vars,setVars]=useState(['','',''])
    const [tables,setTables]=useState([])
    const [tableCols,setTableCols]=useState({})   // cache for cols for the tables in use
    const {type,url,api}=useParams()
    const sql=useRef(null)
    const history=useHistory()
    const {loggedIn}=props




   
  
   

    

    const fetchTableColumns= async (Table)=>
    {
        // table in the form kpe_op.BPerson
        // check if SubQuery
        
        // nur holen, wenn noch nicht definiert
        if(tableCols[Table]===undefined && Table!=='')
        {
            const [db,table]=Table.split('.')
            if(db==='SubQuery')
            {
                const sub=sql.current.subs.find(el=>el.name===table)
                setTableCols((cols)=>({...cols,[Table]:sub.fields}))

            }
            else
            {
                const data=await apiGet('/db/fields/'+Table.replace('.','/'),api)
                const Fields=data.Fields.map(el=>(el.Field))
                setTableCols((cols)=>({...cols,[Table]:Fields}))
            }
        }
    }

    const postCurrentStatement=(actSql,url)=>
    {

        postStatement(api,actSql,type,url)
 
    }
   

        // fetch database List
    useEffect(()=>{
       const fetchDatabases= async ()=>
       {
           const data =await apiGet('/db/databases',api)
           const databases=data.Databases.map(el=>(el.Database))
           setDatabases(databases)
           const json= await apiPost('/db/tables/',databases,api) 
           const myTables=json.Tables.map(t=>t.Table)
           if(sql.current.subs)
               setTables([...sql.current.subs.filter(sub=>sub.subType==='temporary' || sub.subType=== undefined).map(sub=>`SubQuery.${sub.name}`),...myTables])
           else    
              setTables([...myTables])               
       }
       if(!databases.length && loggedIn[api] && sql.current)
            fetchDatabases()
    },[databases,loggedIn,sql.current])



     useEffect(()=>{
        const fetchSql= async () =>
        {

    
            // fetch the sql statement in proper json form
            const data = await apiGet('/statements/'+type+'/'+url,api)
           // store sql structure
            sql.current=data;
            setVars([sql.current.var1,sql.current.var2,sql.current.var3])


        }
        if(loggedIn[api] && url)
            fetchSql()

    },[loggedIn,url])

    useEffect(()=>{
        if(sql.current)
            setVars([sql.current.var1,sql.current.var2,sql.current.var3])
    },[sql.current])
    const panes = [
  { menuItem: 'Main', render: () =>
         <Tab.Pane>
            {
                sql.current===null? '' :
                <>          

                <EditSql
                    databases={databases}
                    tableCols={tableCols}
                    fetchTableColumns={fetchTableColumns}
                    type={type}
                    sql={sql}
                    postStatement={postCurrentStatement}
                    name={url}
                    api={api}
                    vars={vars}
                    tables={tables}
                />
                <Button  onClick={()=>alert(JSON.stringify(sql.current))}>SQL-Json</Button>
                </>
            }
         </Tab.Pane>
},
  { menuItem: 'Conditional', render: () => <Tab.Pane><Header as="h2" content="Conditional Select Query"/></Tab.Pane> },
  { menuItem: 'Sub', render: () => (
      <Tab.Pane>
        <EditSubTab 
            type={type} 
            tableCols={tableCols} 
            fetchTableColumns={fetchTableColumns} 
            databases={databases} sql={sql} 
            api={api}
            url={url}
            vars={vars}
            postStatement={postCurrentStatement}
            tables={tables}
            setTables={setTables}
            setTableCols={setTableCols}
        />
    </Tab.Pane>) },
]



     return(

    <Container>

        <Grid columns={2} divided>
              <Grid.Column>
                    <EditUrl 
                        url={url}
                        sql={sql}
                        type={type}
                        vars={vars}
                        setVars={setVars}
                        api={api}
                    />
                </Grid.Column>
                <Grid.Column>
                {
                    type !=='select' ? '' :
                     <TestStatement urlVars={vars}
                        api= {api}
                        path={url.split(':')[0]}
                        noOfVars={parseInt(url.split(':')[1])}
                    />
                }
                </Grid.Column>
        </Grid>
        <Tab panes={panes} />
        
    </Container>
     )
}        

export default EditSqlTop

