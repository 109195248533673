import React,{useState,useRef,useEffect}  from 'react';

import ShowSelectStatement from './ShowSelectStatement'
import ShowUpdateStatement from './ShowUpdateStatement'
import ShowInsertStatement from './ShowInsertStatement'
import ShowDeleteStatement from './ShowDeleteStatement'
 
 
 
 
 const ShowLint=(props)=>
 {

    const {sql,lint, type}=props
 

    switch(type)
    {
        case 'select':
            return(
                <>
                    <ShowSelectStatement 
                        sql={sql} 
                        lint={lint} 
                    />
                                  
                   
                </>
            )
            break
        case 'update':
            return (
                <ShowUpdateStatement 
                    sql={sql} 
                    lint={lint} 
                />
            )
            break
        case 'insert':
            return (
                <ShowInsertStatement 
                    sql={sql} 
                    lint={lint} 
                />
            )
            break
        case 'insert':
            return (
                <ShowDeleteStatement 
                    sql={sql} 
                    lint={lint} 
                />
            )
            break
        default:
            return null
    }
 }

 export default ShowLint
