import React,{Component} from 'react';
import {baseUrl} from '../config.jsx'
import { Button, Input, Segment, Header, Modal, Embed } from 'semantic-ui-react';
import {apiGet,apiPut,apiDelete,apiPost} from '../lib/api'

export default class TestSelectStatement extends Component
{
    constructor(props)
    {
       super(props);
       this.state=
       {
           vars:['','',''],
           testResult:''
       }
    }
    
   changeState=(VarNumber,data)=>
   {
       this.setState(prevState=>(
           {
               vars: prevState.vars.map((el,index)=> index===VarNumber ? data : el),
           }
       ))
   }
    
    getUrl=()=>
    {
        let url = '/api/db/'+this.props.path
        let i
        for(i=0;i<3;++i)
        {
            if(this.props.urlVars[i]==='')
                break
            else
            {
                if(this.state.vars[i]!=='')
                    url= url+ '/'+this.state.vars[i]
                else
                    break;
            }
        }
        if(i!==this.props.noOfVars)
            return undefined
        else
            return url+'/?t='+Date.now()
    }

    testStatement= async ()=>
    {
        const url=this.getUrl()
        let result
        if(url!==undefined)
        {
            const data=await apiGet(this.getUrl(),this.props.api)
            
            if(data!==false)
                result= JSON.stringify(data, null, 4)
            else
                result=  'NO JSON DATA'
        }
        else
            result= 'please enter all parameters'
        if(result!== this.state.testResult)
            this.setState({testResult:result})

    }

    componentDidUpdate=()=>
    {
        this.testStatement()       
    }

    render=()=>
    {
        let noOfVars
        for(noOfVars=0;noOfVars<3;++noOfVars)
        {
            if(this.props.urlVars[noOfVars]==='')
                break
        }
        return <Segment>
            <Header as="h5">Test</Header>
               { [...Array(noOfVars)].map((el,i) =>(<span>
                     / <Input
                        placeholder={this.props.urlVars[i] }
                        value={this.state.url} 
                        onChange={(event,data)=>{this.changeState(i,data.value)}}
                        />
                    </span>))
               }
            <Modal 
                trigger={<Button>Test Statement</Button>}>}
                <Modal.Header>Returned Data</Modal.Header>
                <Modal.Content data>
                   <pre> {this.state.testResult}</pre>
                </Modal.Content>
            </Modal>
        </Segment>
    }
}
