import React,{Component} from 'react'
import {baseUrl} from '../../config.jsx'
import { Dropdown, Icon, Menu} from 'semantic-ui-react'
import {withRouter} from 'react-router-dom'
import {fetchRepo,pushRepo} from '../../RepoFunctions'
import {postStatement,deleteStatement} from '../../StatementApi.jsx'



// class has tzo be exported with withRouter function in order to macke history work
class EditMenue extends Component
{

  duplicateStatement= ()=>
  {

        postStatement(this.props.api,this.props.sql,this.props.type,this.props.url,true).
        then(newUrl=>
        {
          this.props.history.push(`/${this.props.api}/select/${newUrl}`)
        })
  } 
  
  deleteStatement= async (api)=>
  {

      await deleteStatement(this.props.api,this.props.type,this.props.url)
      this.props.history.push(`/${this.props.api}`)
  }

  render = () => (
      <Dropdown item icon='bars' simple>
        <Dropdown.Menu>
          <Dropdown.Item>
            <a href={`/${this.props.api}`}>Statements</a>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={()=>this.duplicateStatement()}
          >
            Duplicate
          </Dropdown.Item>
          <Dropdown.Item
            onClick={()=>this.deleteStatement()}
          >
            Delete
          </Dropdown.Item>
          <Dropdown.Item
            onClick={()=>pushRepo(this.props.api)}
          >
            Push to Repo
          </Dropdown.Item>
          <Dropdown.Item
            onClick={()=>fetchRepo(this.props.api,this.props.history)}
          >
            Fetch from Repo
          </Dropdown.Item>
        </Dropdown.Menu>
     </Dropdown>

  )
}

export default withRouter(EditMenue)
